<script async setup lang="ts">
import MDSection from '@app/components/response/MDSection.vue'
import type { HLResponseSectionTree } from '@app/state/response';

defineProps<{
  sections: HLResponseSectionTree[]
}>()
</script>

<template>
  <MDSection v-for="section in sections" :section="section" />
</template>

