<script lang="ts" setup>
import Bid from '@app/components/core/Bid.vue'
</script>

<template>
  <div class="main">
    <Bid />
  </div>
</template>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media (min-width: 768px) {
  .main {
    flex-direction: row;
  }
}
</style>