<script lang="ts" setup>
import type { Maybe } from '@Heirloom/common'
import { ref, nextTick, watch, computed } from 'vue'

const props = defineProps<{
  modelValue: Maybe<string>
  validateKey?: (char: string) => boolean
  onSubmit?: (str: string) => any | void | Promise<any> | Promise<void>
  multiline?: boolean
  autofocus?: boolean
}>()
const emit = defineEmits(['update:modelValue'])

const el = ref<HTMLElement | null>(null)

const keydown = (e: KeyboardEvent) => {
  if (props.validateKey && !props.validateKey(e.key)) {
    e.preventDefault()
  }
}

const updateModelValue = () => {
  if (el.value) {
    emit('update:modelValue', el.value.innerText)
  }
}

const model = computed(() => props.modelValue)
const value = ref(props.modelValue)
watch(model, v => {
  if (v !== el.value!.innerText) value.value = v
})

const unfocus = () => {
  updateModelValue()
  if (props.onSubmit && el.value) props.onSubmit(el.value.innerText)
}

const focus = (): void => { setTimeout(() => el.value ? el.value.focus() : focus(), 100) }
if (props.autofocus) focus()
</script>

<template>
  <div class="inline" :class="{ multiline }">
    <div ref="el" contenteditable :class="['editable-text', { multiline }]" @keydown="keydown" @input="updateModelValue"
      @focusout.stop.prevent="unfocus" @keypress.enter="e => multiline ? null : (e.preventDefault(), el!.blur())">{{ value
      }}</div>
  </div>
</template>

<style lang="scss" scoped>
.editable-text {
  outline: none;
  cursor: text;
  text-overflow: ellipsis;
  overflow: scroll;
  width: 100%;
  border-radius: var(--radius);
  transition: .2s;
  padding: 0px 5px !important;
  align-items: center;
  display: flex;

  &.multiline {
    padding: 5px 5px !important;
  }

  background-color: transparent !important;
  white-space: pre-wrap;
  line-height: 150%;

  &:hover {
    background-color: hsl(var(--muted)) !important;
  }

  &:focus {
    text-overflow: unset;
    background-color: hsl(var(--muted)) !important;
    transition: .2s;
  }
}

.editable-text:focus+.enter-info {
  opacity: 1;
  transition: .2s;
}

.enter-info {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-background-color);
  font-size: 12px;
  padding: 0 5px;
  margin-right: 35px;
  margin-top: 4px;
  transition: .2s;
}

.inline {
  display: inline-flex;
  position: relative;
  background-color: transparent !important;

  &.multiline {
    display: block;
    width: 100%;
  }
}
</style>