<script async setup lang="ts">
import { loadResponse, response } from '@app/state/response';
import type { HLComplianceItem } from '@Heirloom/common';
import { computed } from '@vue/reactivity';
import Badge from '@app/components/ui/badge/Badge.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Muted from '@app/components/typography/Muted.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { Button } from '@app/components/ui/button';
import { Icon } from '@iconify/vue';
import { Card } from '@app/components/ui/card';
import Paragraph from '@app/components/typography/Paragraph.vue'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@app/components/ui/table"

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/components/ui/collapsible'

const props = defineProps<{
  item: HLComplianceItem
  order: number
}>()

const sections = computed(() => response.value.filter(r => props.item.sections.includes(r.id)))
</script>

<template>
  <Rows margin="20px 0 0 0">
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Badge variant="secondary" v-if="item.completed">{{ order + 1 }}</Badge>
              <Badge v-else variant="destructive"> {{ order + 1 }}</Badge>
            </TableCell>
            <TableCell>
              {{ item.text }}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Addressed?
            </TableCell>
            <TableCell>
              <Small v-if="item.completed">Yes</Small>
              <Small v-if="!item.completed">No</Small>
            </TableCell>
          </TableRow>
          <TableRow v-if="item.completed">
            <TableCell>
              Sections
            </TableCell>
            <TableCell>
              <Collapsible type="single">
                <Columns flex="1">
                  <CollapsibleTrigger class="text-left">
                    <Columns gap="10">
                      <Badge v-for="section in sections" :key="section.id">{{ section.title }}</Badge>
                    </Columns>
                  </CollapsibleTrigger>
                </Columns>
                <CollapsibleContent>
                  <Columns margin="5px 0 0 0">
                    <Rows>
                      <Muted>{{ item.basis }}</Muted>
                      <br />
                      <Paragraph>{{ item.answer }}</Paragraph>
                    </Rows>
                  </Columns>
                </CollapsibleContent>
              </Collapsible>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  </Rows>
</template>

<style scoped lang="scss"></style>