<script lang="ts" setup>
import { marked } from 'marked'
import { computedAsync } from '@vueuse/core';

const props = defineProps<{ markdown: string }>()

// Custom renderer to handle @tag syntax
const renderer = new marked.Renderer()
const badge = (text: string) => text.replaceAll(/@tag\(("|&quot;)(.+?)("|&quot;)\)/gim, '<div class="badge">$2</div>')

// Configure marked options
marked.setOptions({
  renderer: renderer,
  gfm: true,
  breaks: true,
})

const html = computedAsync(async () => badge(await marked(props.markdown.trim()) ?? ''))
</script>

<template>
  <div class="markdown-body" v-html="html"></div>
</template>

<style lang="scss">
/* Markdown Styles */
.markdown-body {
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  color: var(--primary-font-color);
}

.markdown-body h1 {
  letter-spacing: -.025em;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
}

.markdown-body h2 {
  letter-spacing: -.025em;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;

}

.markdown-body h3 {
  letter-spacing: -.025em;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
}

.markdown-body h4 {
  letter-spacing: -.025em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
}

.markdown-body h5 {
  letter-spacing: -.025em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
}

.markdown-body h6 {
  letter-spacing: -.025em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
}

.markdown-body p {
  color: hsl(var(--foreground));
  line-height: 1.75rem;
  margin-bottom: 1rem;
  font-size: 15px;
}

.markdown-body a {
  color: hsl(var(--primary));
  font-weight: 500;
  text-underline-offset: 4px;
}

.markdown-body a:hover {
  text-decoration: underline;
}

.markdown-body strong {
  font-weight: 600;
}

.markdown-body img {
  max-width: 100%;
  box-sizing: content-box;
}

.markdown-body code {
  font-size: 14px;
  border-radius: 4px;
  padding: 3px 6px;
  background-color: var(--vp-code-bg);
  font-family: monospace;
}

.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: var(--primary-background-color-accent);
  border-radius: 3px;
}

.markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

.markdown-body blockquote {
  font-style: italic;
  padding-left: 1.5rem;
  border-left-width: 2px;
  border-color: hsl(var(--border));
  color: hsl(var(--foreground));
  margin: 0 0 1.5rem 0;
}

.markdown-body ul,
.markdown-body ol {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  color: hsl(var(--foreground));
  line-height: 1.75rem;
  margin-left: 1.5rem;
  list-style-type: disc;
  font-size: 15px;
  list-style: none;
}

.markdown-body li,
.markdown-body li p {
  list-style-type: disc;
  margin-bottom: .5rem;
  color: hsl(var(--muted-foreground));
  font-size: 15px;
}


.markdown-body table {
  display: block;
  width: 100%;
  overflow: auto;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
}

.markdown-body table th {
  font-weight: 700;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  border-color: hsl(var(--border));
}

.markdown-body table th,
.markdown-body table td {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: left;
  border: 1px solid hsl(var(--border));
}

.markdown-body table tr {
  border-top: 1px solid hsl(var(--border));
}

.markdown-body hr {
  height: 1px;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: hsl(var(--border));
  width: 100%;
  border: 0;
}

.markdown-body .badge {
  img {
    max-height: 15px;
    margin-left: 2px;
    margin-right: 5px;
    vertical-align: text-top;
  }

  color: hsl(var(--background));
  font-weight: 600;
  font-size: .75rem;
  line-height: 1rem;
  padding-top: .125rem;
  padding-bottom: .125rem;
  padding-left: .625rem;
  padding-right: .625rem;
  background-color: hsl(var(--primary));
  border-color: transparent;
  border-width: 1px;
  border-radius: 9999px;
  align-items: center;
  display: inline-flex;
  white-space: nowrap;
  font-style: normal;
  flex-shrink: 0;
  height: fit-content;
  user-select: none;
  margin-left: 5px;
  letter-spacing: 0;
  position: relative;
  top: -3px;
}
</style>