<script async setup lang="ts">
import Icon from '@app/components/common/Icon.vue'
import Markdown from '@app/components/common/Markdown.vue'
import type { HLReference } from '@Heirloom/common';
import { ref } from '@vue/reactivity'

defineProps<{
  reference: HLReference["sections"][number]
}>()

const expand = ref(false)
</script>

<template>
  <div class="reference" :key="reference.id">
    <div class="title" @click.stop.prevent="expand = !expand">
      {{ reference.title }}
      <Icon :name="expand ? 'chevron-down' : 'chevron-right'" color="blue" />
    </div>
    <div v-if="expand" class="markdown">
      <Markdown class="content" :markdown="reference.markdown" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  user-select: none;
  gap: 2px;
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  background-color: var(--primary-background-color-accent);
  width: fit-content;
  padding: 2px 4px;
  border-radius: var(--primary-border-radius);

  img {
    width: 10px;
    margin-top: -1px;
  }
}

.markdown {
  margin-top: 8px;
  margin-left: 2px;
  padding-left: 8px;
  border-left: 1px solid var(--primary-background-color);
  .content {
    background-color: var(--secondary-background-color);
    color: var(--primary-font-color);
    border-radius: var(--primary-border-radius);
    padding: 8px;
    font-size: 12px !important;
  }
}
</style>