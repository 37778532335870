<script async setup lang="ts">
import type { HLSolutioningItem } from '@Heirloom/common';
import EditableText from '@app/components/common/EditableText.vue';
import { answerSolutioning } from '@app/state/solutioning';
import { Card } from '@app/components/ui/card';
import Rows from '@app/components/ui/flex/Rows.vue';
import Large from '@app/components/typography/Large.vue';
import Muted from '@app/components/typography/Muted.vue';

const props = defineProps<{
  item: HLSolutioningItem
}>()

const doAnswer = () => answerSolutioning(props.item.id, props.item.answer ?? '')
</script>

<template>
  <Rows margin="15px 0 0 0">
    <Card>
      <Rows padding="15px 10px">
        <Rows padding="0 5px">
          <Large>
            {{ item.question }}
          </Large>
        </Rows>
        <Rows margin="5px 0 0 0">
          <Muted>
            <EditableText v-model="item.answer" multiline @submit="doAnswer" />
          </Muted>
        </Rows>
      </Rows>
    </Card>
  </Rows>
</template>

<style scoped lang="scss"></style>