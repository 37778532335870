<script setup lang="ts">
import { computed } from '@vue/reactivity'

const props = defineProps<{
  left?: boolean;
  right?: boolean;
  center?: boolean;
  top?: boolean;
  middle?: boolean;
  bottom?: boolean;
  spread?: boolean;
  width?: string;
  maxWidth?: string;
  flex?: string;
  padding?: string;
  margin?: string;
  gap?: string;
  color?: string;
  scroll?: boolean;
}>()

const gap = computed(() => props.gap ? `${props.gap}px` : '0')

const style = computed(() => ({
  width: props.width ? `${props.width}` : '',
  maxWidth: props.maxWidth ? `${props.maxWidth}` : '',
  flex: props.flex ? props.flex : '',
  padding: props.padding ? `${props.padding}` : '0',
  margin: props.margin ? `${props.margin}` : '0',
  gap: gap.value,
  backgroundColor: props.color ? props.color : 'transparent',
}))
</script>

<template>
  <div class="column" :style="style" :class="{ left, right, center, top, middle, bottom, scroll, spread }">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.column {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  & > * {
    width: 100%;
  }
}
.scroll {
  overflow: scroll;
  display: unset;
  & > * {
    margin-bottom: v-bind(gap) !important;
  }
}
.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}

.center {
  align-items: center;
}

.top {
  justify-content: flex-start;
}

.middle {
  justify-content: center;
}

.bottom {
  justify-content: flex-end;
}

.spread {
  justify-content: space-between;
}
</style>