<script async setup lang="ts">
import { watch } from 'vue';
import { ref } from '@vue/reactivity';
import type { Maybe } from '@Heirloom/common';
import { Badge } from '@app/components/ui/badge';
import { Icon } from '@iconify/vue'
import { Card } from '@app/components/ui/card';
import Loader from '@app/components/common/Loader.vue';
import { getSolicitationPage, solicitationPage } from '@app/state/solicitation';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { Button } from '@app/components/ui/button';
import Large from '@app/components/typography/Large.vue';
import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@app/components/ui/collapsible';
import Markdown from '@app/components/common/Markdown.vue';

const props = defineProps<{
  page: {
    id: string
    pageNumber: number
    markdown: Maybe<string>
    tags: string[]
  }
  total: number
}>()

const open = ref(true)
const mode = ref<'image' | 'markdown'>('markdown')
const viewImage = async () => {
  mode.value = 'image'
  if (solicitationPage.value?.id !== props.page.id) {
    await getSolicitationPage(props.page.id)
  }
}
watch(solicitationPage, () => {
  if (solicitationPage.value?.id !== props.page.id) {
    mode.value = 'markdown'
  }
})
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Card>
        <Columns padding="20px">
          <Collapsible v-model:open="open" type="single">
            <Columns flex="1">
              <CollapsibleTrigger :key="page.id" class="text-left">
                <Columns spread center>
                  <Columns flex="1" gap="10">
                    Page {{ page.pageNumber + 1 }} / {{ total }}
                    <Badge v-for="tag in page.tags" :key="tag">{{ tag }}</Badge>
                  </Columns>
                  <Columns width="30px">
                    <Button variant="ghost" size="icon" v-if="mode === 'markdown'" @click.stop.prevent="viewImage">
                      <Icon icon="lucide:image" />
                    </Button>
                    <Button variant="ghost" size="icon" v-if="mode === 'image'" @click.stop.prevent="mode = 'markdown'">
                      <Icon icon="lucide:letter-text" />
                    </Button>
                  </Columns>
                </Columns>
              </CollapsibleTrigger>
            </Columns>
            <CollapsibleContent :value="page.id">
              <Markdown v-if="mode === 'markdown'" :markdown="page.markdown ?? ''" />
              <img v-if="mode === 'image' && solicitationPage" class="page-img" :src="solicitationPage.image"
                crossorigin="anonymous" />
            </CollapsibleContent>
          </Collapsible>
        </Columns>
      </Card>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem>
        <span>Copy</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:copy" />
        </ContextMenuShortcut>
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
</template>

<style scoped lang="scss">
.markdown {
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--primary-background-color);
  padding: 12px;

  pre {
    color: var(--strong-font-color);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-color);
    user-select: none;

    img {
      margin-top: -2px;
      margin-right: 6px;
    }

    .page-tag {
      text-transform: uppercase;
      height: 20px;
      top: -1px;
      margin-left: 8px;
      position: relative;
      color: var(--green);
      background-color: var(--green-bg);
      user-select: none;
    }
  }

  .page-img {
    width: 80%;
    margin: auto;
  }
}

.loader {
  margin-top: 140px;
}
</style>