<script async setup lang="ts">
import type { HLSolicitationMeta } from '@Heirloom/common';
import { support } from '@app/state/common';
import { Icon } from '@iconify/vue'
import Paragraph from '@app/components/typography/Paragraph.vue'
import { Badge } from '@app/components/ui/badge'
import Rows from '@app/components/ui/flex/Rows.vue'
import Small from '@app/components/typography/Small.vue';

const props = defineProps<{
  solicitation: HLSolicitationMeta
}>()
</script>

<template>
  <Rows class="solicitation-card" gap="10">
    <div class="thumb">
      <Badge class="date" variant="default">
        {{ new Date(props.solicitation.created).toNiceDateTime() }}
      </Badge>
      <img crossorigin="anonymous" :src="solicitation.thumbnail ?? '/assets/img/test.png'" height="435px" />
      <Badge class="status" variant="secondary">
        <div class="text-sky-500" v-if="props.solicitation.status === 'created'">
          Uploading
        </div>
        <div class="text-sky-500" v-if="props.solicitation.status === 'processing'">
          Parsing
        </div>
        <template v-if="props.solicitation.status === 'parsed'">
          <div class="text-rose-500" v-if="new Date(props.solicitation.created).valueOf() > Date.now() - 1000 * 60 * 10">
            Contact Support
          </div>
          <div class="text-sky-500" v-else>
            Indexing
          </div>
        </template>
        <div class="text-rose-500" v-if="props.solicitation.status === 'error'">
          Contact Support
        </div>
        <div class="text-indigo-500" v-if="props.solicitation.status === 'tagging'">
          Step 1: Tag
        </div>
        <div class="text-sky-500" v-if="props.solicitation.status === 'extraction'">
          Extracting
        </div>
        <div class="text-indigo-500" v-if="props.solicitation.status === 'solutioning'">
          Step 2: Solutioning
        </div>
        <div class="text-sky-500" v-if="props.solicitation.status === 'generating'">
          Generating
        </div>
        <div class="text-indigo-500" v-if="props.solicitation.status === 'outlining'">
          Step 3: Outline
        </div>
        <div class="text-sky-500" v-if="props.solicitation.status === 'writing'">
          Writing
        </div>
        <div class="text-sky-500" v-if="props.solicitation.status === 'checking'">
          Assessing
        </div>
        <div class="text-indigo-500" v-if="props.solicitation.status === 'compliance'">
          Step 4: Compliance
        </div>
        <div class="text-emerald-500" v-if="props.solicitation.status === 'complete'">
          Complete
        </div>
        <div class="text-rose-500" v-if="props.solicitation.status === 'deleted'">
          Deleted
        </div>
      </Badge>
    </div>
    <Small class="title">
      {{ props.solicitation.name.replace('.pdf', '') || 'Untitled' }}
    </Small>
  </Rows>
</template>

<style scoped lang="scss">
.solicitation-card {
  min-width: 220px;
  max-width: 310px;
  width: minmax(220px, 310px);
  cursor: pointer;
  transition: .2s;

  img {
    object-fit: cover;
    width: minmax(220px, 310px);
    aspect-ratio: 1 / 1.414;
    transition: .2s;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.thumb {
  position: relative;
  overflow: hidden;
  border-radius: calc(0.5em - 2px);
  border: 1px solid hsl(var(--border));
  background-color: #fff;

  .status {
    position: absolute;
    bottom: 3px;
    top: unset;
    right: unset;
    background-color: hsl(var(--background));
    white-space: nowrap;
    opacity: 1;
    padding: 5px 4px;
    cursor: pointer;
    left: 3px;
  }

  .date {
    position: absolute;
    top: 3px;
    white-space: nowrap;
    opacity: 1;
    z-index: 1;
    padding: 5px 4px;
    cursor: pointer;
    right: 3px;
  }
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
  z-index: 1;
}
</style>