import { ref } from "@vue/reactivity"
import type { HLReference } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { tabs } from "./view"

interface UserMessage {
  type: 'query'
  message: string
}

interface AIMessage {
  type: 'response'
  message: string
  context: HLReference['sections']
}

export interface Conversation {
  id: string
  messages: (UserMessage | AIMessage)[]
  summaries: string[]
}
export const conversations = ref<Conversation[]>([])

export const chat = (message: string, id?: string) => {
  if (id) {
    const conversation = conversations.value.find(c => c.id === id)
    if (!conversation) throw new Error("Conversation not found.")
    conversation.messages.push({ type: 'query', message })
    sock.pub('reference/chat-all', {
      message,
      conversationId: id,
      summaries: conversation.summaries,
      history: conversation.messages.map(m => ({ type: m.type, message: m.message }))
    })
    return conversation.id
  } else {
    const conversation: Conversation = {
      id: crypto.randomUUID(),
      messages: [{ type: 'query', message }],
      summaries: []
    }
    conversations.value.push(conversation)
    sock.pub('reference/chat-all', {
      message,
      conversationId: conversation.id
    })
    return conversation.id
  }
}
BIND('reference:chat-all', () => ({ messagePart, sections, conversationId, title, summary }) => {
  const conversation = conversations.value.find(c => c.id === conversationId)
  if (!conversation) throw new Error("Conversation not found.")
  if (messagePart) {
    const lastMessage = conversation.messages.last()
    if (!lastMessage || lastMessage.type !== 'response') throw new Error("Last message is not an AI response.")
    lastMessage.message += messagePart
    conversation.messages.splice(conversation.messages.length - 1, 1, lastMessage)
  }
  if (sections) conversation.messages.push({ type: 'response', message: '', context: sections })
  if (title) {
    const index = tabs.value.findIndex(t => t.id === conversation.id)
    if (index === -1) throw new Error("Conversation not found in tabs.")
    tabs.value[index].name = title
  }
  if (summary) conversation.summaries.push(summary)
})
