import { ref } from "@vue/reactivity"
import type { HLComplianceItem } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { solicitation } from "@app/state/solicitation"

//? Load
export const compliance = ref<HLComplianceItem[]>([])
export const loadCompliance = (id: string) => {
  if (id !== solicitation.value?.id) compliance.value = []
  sock.pub('compliance/load', { id })
}
BIND('compliance:load', () => s => compliance.value = s)

//? Answer
export const checkCompliance = () => sock.pub('compliance/check', { id: solicitation.value!.id })

//? Delta
BIND('△:compliance', () => s => s.id === solicitation.value?.id ? loadCompliance(s.id) : null)

//? Download
export const downloadCompliance = (): void => {
  // Group items by category
  const groupedByCategory = compliance.value.reduce((acc, item) => {
    const category = item.category || 'Uncategorized'
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(item)
    return acc
  }, {} as Record<string, HLComplianceItem[]>)

  // Generate CSV content
  const headers = ['Category', 'Text', 'Basis', 'Completed', 'Answer']
  const csvRows = [headers.join(',')]

  Object.entries(groupedByCategory).forEach(([category, items]) => {
    items.forEach(item => {
      const row = [
        `"${category.replace(/"/g, '""')}"`,
        `"${item.text.replace(/"/g, '""')}"`,
        `"${item.basis.replace(/"/g, '""')}"`,
        item.completed ? 'Yes' : 'No',
        `"${(item.answer || '').replace(/"/g, '""')}"`
      ]
      csvRows.push(row.join(','))
    })
  })

  const csvContent = csvRows.join('\n')

  // Create and download the file
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')

  link.href = URL.createObjectURL(blob)
  link.download = (solicitation.value?.title ?? 'compliance').replace(/[^\w\s]/gi, '') + '.csv'
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}