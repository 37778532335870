<script lang="ts" setup>
import type { Maybe } from '@Heirloom/common'
import { ref, nextTick, watch, computed } from 'vue'

const props = defineProps<{
  modelValue: Maybe<string>
  validateKey?: (char: string) => boolean
  onSubmit?: (str: string) => any | void | Promise<any> | Promise<void>
  multiline?: boolean
  autofocus?: boolean
  placeholder?: string
  borderless?: boolean
}>()
const emit = defineEmits(['update:modelValue', 'submit'])

const el = ref<HTMLElement | null>(null)

const keydown = (e: KeyboardEvent) => {
  if (props.validateKey && !props.validateKey(e.key)) {
    e.preventDefault()
  }
  if (e.key === 'Enter') {
    if (e.shiftKey) {
      e.preventDefault()
      if (el.value!.textContent!.trim() === '') {
        document.execCommand('insertHTML', false, '<br>&#8203;')
      } else {
        document.execCommand('insertLineBreak')
      }
    } else if (!props.multiline) {
      e.preventDefault()
      emit('submit', el.value!.innerText)
    }
  }
}

const updateModelValue = () => {
  if (el.value) {
    emit('update:modelValue', el.value.innerText)
  }
}

const model = computed(() => props.modelValue)
const value = ref(props.modelValue)
watch(model, v => {
  if (v !== el.value!.innerText) value.value = v
})

const unfocus = () => {
  updateModelValue()
}

const focus = (): void => { setTimeout(() => el.value ? el.value.focus() : focus(), 100) }
if (props.autofocus) focus()

</script>

<template>
  <div class="inline" :class="{ multiline }">
    <div
      ref="el"
      contenteditable
      :class="['editable-text', { multiline, borderless }]"
      @keydown="keydown"
      @input="updateModelValue"
      @focusout.stop.prevent="unfocus"
      @keypress.enter="e => multiline ? null : (e.preventDefault(), el!.blur())"
      :placeholder="placeholder"
    >
      {{ value }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editable-text {
  text-align: left;
  cursor: text;
  display: flex;
  border: 1px solid transparent;
  width: 100%;
  border-radius: var(--radius);
  box-shadow: inset 0 0 0 1px hsl(var(--input));
  min-height: calc(2.5em + 1px);
  background-color: hsl(var(--background));
  padding: 0.5em 0.75em;
  font-size: var(--text-sm);
  color: hsl(var(--foreground));
  white-space: pre-wrap;
  word-break: break-word;

  &::file-selector-button {
    border: 0;
    background-color: transparent;
    font-size: var(--text-sm);
    font-weight: 500;
  }

  &:empty::before {
    content: attr(placeholder);
    color: hsl(var(--muted-foreground));
  }

  &:focus-visible {
    outline: none;
    border: 1px solid hsl(var(--primary) / 0.9);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.multiline {
    height: auto;
    min-height: 2.5rem;
  }

  &.borderless {
    border: none;
  }
}

.inline {
  display: inline-flex;
  position: relative;
  background-color: transparent !important;

  &.multiline {
    display: block;
    width: 100%;
  }
}
</style>