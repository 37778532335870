<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  itemWidth?: string
  columns?: string
  maxColumns?: string
  gap?: string
  flex?: string
  padding?: string
  margin?: string
  color?: string
  left?: boolean
  right?: boolean
  center?: boolean
  top?: boolean
  middle?: boolean
  bottom?: boolean
  spread?: boolean
  scroll?: boolean
}>()

const gap = computed(() => props.gap ? `${props.gap}px` : '0')

const gridTemplateColumns = computed(() => {
  if (!props.itemWidth) return ''
  const columnWidth = props.itemWidth.includes('px') ? props.itemWidth : `${props.itemWidth}px`
  const maxCols = props.maxColumns ? parseInt(props.maxColumns) : Infinity
  if (props.columns) {
    const cols = Math.min(parseInt(props.columns), maxCols)
    return `repeat(${cols}, ${columnWidth})`
  }
  return `repeat(auto-fit, minmax(${columnWidth}, 1fr))`
})

const style = computed(() => ({
  flex: props.flex ? props.flex : '',
  padding: props.padding ? `${props.padding}` : '0',
  margin: props.margin ? `${props.margin}` : '0',
  gap: gap.value,
  gridTemplateColumns: gridTemplateColumns.value,
  backgroundColor: props.color ? props.color : 'transparent',
}))
</script>

<template>
  <div class="grid" :style="style" :class="{ left, right, center, top, middle, bottom, scroll, spread }">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.grid {
  box-sizing: border-box;
  display: grid;
  align-items: stretch;
}

.scroll {
  overflow: scroll;
  display: unset;
  & > * {
    margin-right: v-bind(gap) !important;
  }
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.top {
  align-items: flex-start;
}

.bottom {
  align-items: flex-end;
}

.middle {
  align-items: center;
}

.spread {
  justify-content: space-between;
}
</style>