<script async setup lang="ts">
import Markdown from '@app/components/common/Markdown.vue';
import Badge from '@app/components/ui/badge/Badge.vue';
import Section from '@app/components/write/Section.vue';
import Page from '@app/components/write/Page.vue';
import MinField from '@app/components/common/MinField.vue';
import { ref } from '@vue/reactivity';
import { computed, nextTick, watch } from 'vue';
import { chatSolicitation, solicitation, context as C, answer, tagSection, loadSolicitation } from '@app/state/solicitation';
import type { HLSolicitationSection, Maybe } from '@Heirloom/common';
import { green, greenBg, red, redBg, segment, Segment } from '@app/state/view';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { Icon } from '@iconify/vue'
import Button from '@app/components/ui/button/Button.vue'
import Textfield from '@app/components/ui/textfield/Textfield.vue';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@app/components/ui/tabs"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@app/components/ui/dropdown-menu'
import { isStaff } from '@app/state/auth';

const query = ref('')

const uncheck = (sections: HLSolicitationSection[]): (HLSolicitationSection & { checked: boolean, tokens: number })[] =>
  sections.map(s => ({ ...s, checked: false, tokens: Math.round(s.markdown.length / 4) }))
const context = ref<(HLSolicitationSection & { checked: boolean, tokens: number })[]>(uncheck(solicitation.value?.sections ?? []))
const search = async () => {
  if (query.value) {
    chatSolicitation(query.value)
  } else {
    context.value = uncheck(solicitation.value?.sections ?? [])
    answer.value = ''
  }
}
watch(C, c => context.value = uncheck(c ?? solicitation.value?.sections ?? []))
watch(solicitation, s => context.value = uncheck(s?.sections ?? []))

const tokenCounts = computed(() => {
  const ret = {
    'total': 0,
    'requirements': 0,
    'submission-instructions': 0,
    'evaluation-criteria': 0
  }
  for (const section of context.value) {
    section.tags.map(tag => {
      if (tag === 'requirements') ret['requirements'] += section.tokens
      if (tag === 'submission-instructions') ret['submission-instructions'] += section.tokens
      if (tag === 'evaluation-criteria') ret['evaluation-criteria'] += section.tokens
    })
  }
  ret['total'] = ret['requirements'] + ret['submission-instructions'] + ret['evaluation-criteria']
  return ret
})

const tag = (tag: "requirements" | "submission-instructions" | "evaluation-criteria") => {
  checkedSections.value.map(s => tagSection(s.id, tag.toLowerCase().replace(/ /gim, '-')))
}
const checkedSections = computed(() => context.value.filter(s => s.checked))
const lastChecked = ref<Maybe<number>>(null)
const lastLastChecked = ref<Maybe<number>>(null)
watch(lastChecked, (l, ll) => lastLastChecked.value = ll)
const shiftCheck = async () => {
  await nextTick()
  console.log('shift check', lastLastChecked.value, lastChecked.value)
  if (lastLastChecked.value == null || lastChecked.value == null) return;
  for (let i = lastLastChecked.value; i < lastChecked.value; i++) {
    console.log('check', i)
    context.value[i].checked = context.value[lastLastChecked.value].checked
  }
}

const mode = ref<'section' | 'page' | 'document'>('section')
</script>

<template>
  <Rows v-if="solicitation">
    <Tabs v-model="mode" default-value="section" class="tabs-root">
      <Rows height="76px" padding="20px">
        <Columns spread gap="20">
          <Columns center middle gap="10" flex="1">
            <Columns width="fit-content">
              <TabsList>
                <TabsTrigger value="section">
                  <Icon height="20px" icon="lucide:rows-3" />
                </TabsTrigger>
                <TabsTrigger value="page">
                  <Icon height="20px" icon="lucide:file-stack" />
                </TabsTrigger>
                <TabsTrigger value="document">
                  <Icon height="20px" icon="lucide:letter-text" />
                </TabsTrigger>
              </TabsList>
            </Columns>
            <Columns flex="1">
              <template v-if="mode === 'section'">
                <Textfield borderless v-model="query" @submit="search" placeholder="Search sections..." />
              </template>
              <template v-else>
              </template>
            </Columns>
          </Columns>
          <Columns width="fit-content" bottom center>
            <Columns width="fit-content" gap="10" v-if="checkedSections.length > 0">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <Button variant="secondary">
                    <template #icon>
                      <Icon icon="lucide:tag" />
                    </template>
                    <span class="mr-2">Tag Sections</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem @click="tag('requirements')">
                    <Icon icon="tabler:square-letter-c-filled" class="mr-2" />
                    <span class="mr-2">Requirements</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem @click="tag('submission-instructions')">
                    <Icon icon="tabler:square-letter-l-filled" class="mr-2" />
                    <span class="mr-2">Submission Instructions</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem @click="tag('evaluation-criteria')">
                    <Icon icon="tabler:square-letter-m-filled" class="mr-2" />
                    <span class="mr-2">Evaluation Criteria</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </Columns>
          </Columns>
        </Columns>
      </Rows>

      <Rows scroll flex="1" padding="0 20px">
        <TabsContent value="section" v-if="mode === 'section'">
          <Rows gap="10">
            <div class="markdown" v-if="answer">
              <Markdown :markdown="answer" />
            </div>
            <Columns gap="10" v-if="isStaff" class="token-count">
              <Badge variant="secondary" title="Total">T: {{ tokenCounts["total"] }} tok</Badge>
              <Badge title="Requirements">C: {{ tokenCounts["requirements"] }} tok</Badge>
              <Badge title="Submission Instructions">L: {{ tokenCounts["submission-instructions"] }} tok</Badge>
              <Badge title="Evaluation Criteria">M: {{ tokenCounts["evaluation-criteria"] }} tok</Badge>
            </Columns>
            <Section v-for="(section, i) in context" :key="section.id" :section="section"
              v-model="section.checked" @check="lastChecked = i" @shift-check="shiftCheck" />
          </Rows>
        </TabsContent>
        <TabsContent value="page" v-if="mode === 'page'">
          <Rows gap="10">
            <Page v-for="page in solicitation.pages" :key="page.id" :page="page" :total="solicitation.pages.length" />
          </Rows>
        </TabsContent>
        <TabsContent value="document" v-if="mode === 'document'">
          <Markdown :markdown="solicitation.markdown ?? ''" />
        </TabsContent>
      </Rows>
    </Tabs>
  </Rows>
</template>

<style lang="scss" scoped></style>