import { PublicClientApplication, type AccountInfo, type RedirectRequest } from "@azure/msal-browser"
import { ref } from 'vue'

const config = {
  auth: {
    clientId: "fc6070c4-32f9-488a-89fd-b6fec2b5bb7b",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: window.location.href,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
}

const graphScopes: RedirectRequest = {
  scopes: ["user.read"]
}

const msalInstance = new PublicClientApplication(config)

await msalInstance.initialize()
await msalInstance.handleRedirectPromise();
export const MS_User = ref(
  msalInstance.getAllAccounts().length > 0
    ? msalInstance.getAllAccounts()[0].username
    : ""
)

export const login = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0 && accounts[0].idToken) return accounts[0].idToken
  console.log("Not logged in. Starting flow...")
  await msalInstance.loginRedirect(graphScopes)
  throw new Error("Halting execution for login.")
  return ''
}
