import type { Maybe } from "@Heirloom/common"
import { ref } from "@vue/reactivity"
import Logger from "@app/utils/logger"
import { BIND, connected, error, slug, sock, socket } from "@app/state/common"
import { monitorScaling } from "@app/state/scaling"
import { watch } from "vue"
const Log = new Logger("Auth")

const tenant = slug ?? '<invalid path>'
export const tenantName = ref<Maybe<string>>()
export const userName = ref<Maybe<string>>()
export const isStaff = ref<boolean>(false)

export const isBound = ref<boolean>(false)
BIND("bound", ({ socket }) => ({ id, name, username, staff }) => {
    if (id !== socket.id) throw new Error("Socket ID mismatch on bound.")
    tenantName.value = name
    userName.value = username
    isBound.value = true
    isStaff.value = staff
    Log.success("Successfully bound to tenant.")
    localStorage.setItem(`HL:${tenant}:token`, TOKEN.value!)
    document.title = `GovPro Bid | ${name}`
    monitorScaling()
})

export const TOKEN = ref<Maybe<string>>(localStorage.getItem(`HL:${tenant}:token`))
export const bind = () => {
    if (!TOKEN.value) throw new Error("No token to use in bind.")
    if (!connected.value) socket.connect()
    sock.pub('bind', { tenant, token: TOKEN.value })
    Log.info(`Attempting to bind to tenant <${tenant}>`)
    connected.value = true
    error.value = null
    setTimeout(() => retrying = false, 10_000)
}

if (TOKEN.value) bind()

let retrying = false
let retryTimeout = 1
const reconnect = () => {
    if (connected.value) return;
    retrying = true;
    Log.info("Reconnecting...")
    if (retryTimeout <3_0) retryTimeout *= 2
    bind()
    setTimeout(reconnect, retryTimeout * 1000)
}
watch(connected, (isConnected, wasConnected) => {
    if ((!isConnected && wasConnected) && !retrying) reconnect()
})
