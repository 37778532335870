<script lang="ts" setup>
import Icon from '@app/components/common/Icon.vue';
import type defs from '@app/utils/icon-definitions'
import { Command } from 'vue-command-palette'
import { ref, watch } from 'vue'
import { useMagicKeys } from '@vueuse/core'
import { Segment, segment } from '@app/state/view';
const keys = useMagicKeys()
const CmdK = keys['Meta+K']
const Esc = keys['Escape']
const visible = ref(false)
watch(CmdK, (v) => { if (v) visible.value = !visible.value })
watch(Esc, (v) => { if (v) visible.value = false })
type DO = () => void | any | Promise<any>;
const d = (task: DO) => () => {
  task()
  visible.value = false
}

const items: {
  icon: keyof typeof defs,
  label: string,
  shortcut: string[],
  perform: () => void | any | Promise<any>,
}[] = [
    {
      icon: 'bug',
      label: 'Report a Bug',
      shortcut: ['shift', 'N'],
      perform: d(() => window.open('https://github.com/govpro-ai/tomato/issues'))
    },
    {
      icon: 'align-left',
      label: 'Switch to Full Markdown',
      shortcut: ['shift', 'M'],
      perform: d(() => segment.value = Segment.Markdown)
    },
    {
      icon: 'integrations',
      label: 'Switch to Sections',
      shortcut: ['shift', 'S'],
      perform: d(() => segment.value = Segment.Sections)
    },
    {
      icon: 'drafts',
      label: 'Switch to Pages',
      shortcut: ['shift', 'P'],
      perform: d(() => segment.value = Segment.Pages)
    }
  ]
</script>

<template>
  <Command.Dialog :visible="visible" theme="govpro">
    <template #header @click="visible = false">
      <div command-govpro-badge="">{{ "GovPro Bid" }}</div>
      <Command.Input placeholder="Type a command or search..." />
    </template>
    <template #body>
      <!-- <Command.Loading> Hang on... </Command.Loading> -->
      <Command.List>
        <Command.Empty>No results found.</Command.Empty>
        <Command.Item v-for="item in items" :data-value="item.label" :shortcut="item.shortcut" :perform="item.perform"
          @select="item.perform">
          <Icon :name="item.icon" color="gray" />
          <div>{{ item.label }}</div>
          <div command-govpro-shortcuts>
            <kbd v-for="key in item.shortcut" key="key">{{ key === 'shift' ? '⇧' : key }}</kbd>
          </div>
        </Command.Item>
      </Command.List>
    </template>
  </Command.Dialog>
</template>

<style lang="scss">


.govpro {
  [command-dialog-mask] {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    backdrop-filter: blur(5px) !important;
    line-height: inherit;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--un-default-border-color, #e5e7eb);
    background-color: #0000004d;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 200;
    animation: 333ms cubic-bezier(.16,1,.3,1) 0s 1 normal none running shown;
  }
  [command-dialog-wrapper] {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    line-height: inherit;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--un-default-border-color, #e5e7eb);
    position: relative;
    flex-direction: column;
    margin: 20vh auto auto;
    animation: 333ms cubic-bezier(.16,1,.3,1) 0s 1 normal none running shown;
    max-width: 640px;
    width: 100%;
    background: #252332cf;
    border-radius: 7px;
    overflow: hidden;
    padding: 4px;
  }
  [command-dialog-header] {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    line-height: inherit;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--un-default-border-color, #e5e7eb);
  }
  [command-govpro-badge] {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    line-height: inherit;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--un-default-border-color, #e5e7eb);
    height: 24px;
    padding: 8px;
    font-size: 12px;
    color: var(--primary-font-color);
    background: var(--secondary-background-color);
    border-radius: 4px;
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 7px 7px 0;
  }
  [command-input] {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    box-sizing: border-box;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-weight: inherit;
    line-height: inherit;
    border: none;
    width: 100%;
    font-size: 18px;
    padding: 20px 8px;
    outline: none;
    background: var(--bg);
    color: var(--primary-font-color);
    border-bottom: 1px solid var(--primary-background-color);
    border-radius: 0;
    caret-color: #6e5ed2;
    margin: 0;
  }
  [command-dialog-body] {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    line-height: inherit;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--un-default-border-color, #e5e7eb);
  }
  [command-list] {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    line-height: inherit;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--un-default-border-color, #e5e7eb);
    max-height: 360px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: .1s ease;
    transition-property: height;
  }
}

.govpro {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  [command-root] {
    kbd {
      background: var(--secondary-background-color);
      color: var(--primary-font-color);
      height: 20px;
      width: 24px;
      border-radius: 4px;
      border: 1px solid var(--primary-background-color);
      padding: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 100%;
    height: 100%;
  }

  [command-govpro-shortcuts] {
    display: flex;
    margin-left: auto;
    gap: 8px;

    kbd {
      font-size: 13px;
      color: var(--primary-font-color);
    }
  }

  [command-input] {


    &::placeholder {
      color: var(--primary-font-color);
    }
  }

  [command-item] {
    content-visibility: auto;
    cursor: pointer;
    height: 48px;
    font-size: 14px;
    display: flex;
    border-radius: var(--primary-border-radius);
    align-items: center;
    gap: 12px;
    padding: 0 16px;
    color: var(--primary-font-color);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;
    position: relative;

    &[aria-selected='true'],
    &:hover {
      background: #07051387;
    }

    &[aria-disabled='true'] {
      color: var(--primary-font-color);
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--secondary-background-color);
    }

    svg {
      width: 16px;
      height: 16px;
      color: var(--primary-font-color);
    }
  }


  *+[command-group] {
    margin-top: 8px;
  }

  [command-empty=''] {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
    color: var(--primary-font-color);
  }

  [command-separator] {
    height: 1px;
    width: 100%;
    background: var(--secondary-background-color);
    margin: 4px 0;
  }

  [command-dialog-mask] {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px) !important;
  }

  [command-dialog-wrapper] {
    max-width: 640px;
    width: 100%;
    background: #252332e6;
    border-radius: 7px;
    overflow: hidden;
    padding: 4px;

    .dark & {
      background: govpro-gradient(136.61deg,
          rgb(39, 40, 43) 13.72%,
          rgb(45, 46, 49) 74.3%);
    }
  }
}
.gray{
  width: 23px !important;
}
</style>