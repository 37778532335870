<script lang="ts" setup>
import type { Maybe } from '@Heirloom/common'
import { ref } from '@vue/reactivity'

const props = defineProps<{
  modelValue: Maybe<string>
  validateKey?: (char: string) => boolean
  submit?: (str: string) => any | void | Promise<any> | Promise<void>
  multiline?: boolean
  autofocus?: boolean
  placeholder?: string
}>()
const emit = defineEmits(['update:modelValue'])

const el = ref<HTMLElement | null>(null)

const keydown = (e: KeyboardEvent) => {
  if (props.validateKey) {
    if (!props.validateKey(e.key)) {
      e.preventDefault()
      return
    }
  }
  emit('update:modelValue', el.value!.innerText)
}

const unfocus = () => {
  emit('update:modelValue', el.value!.innerText)
  if (props.submit) props.submit(el.value!.innerText)
}

const focus = (): void => { setTimeout(() => el.value ? el.value.focus() : focus(), 100) }
if (props.autofocus) focus()
</script>

<template>
  <div class="wrapper">
    <div :placeholder="props.placeholder" v-if="multiline" ref="el" contenteditable class="editable-text multiline" v-html="modelValue" @keypress="keydown"
      @focusout.stop.prevent="unfocus">
    </div>
    <div v-else :placeholder="props.placeholder" ref="el" contenteditable class="editable-text" v-text="modelValue" @keypress="keydown"
      @keypress.enter.prevent="el!.blur()" @focusout.stop.prevent="unfocus">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.editable-text {
  outline: none;
  cursor: text;
  text-overflow: ellipsis;
  overflow: scroll;
  width: 100%;
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-background-color-hover);
  box-shadow: inset 0 0 0 1px var(--secondary-background-color);
  transition: .2s;
  padding: 8px;
  padding-left: 12px;
  font-size: 14px;
  color: var(--primary-font-color);
  &.multiline { padding: 0px 0px !important; }

  &::before {
    content: attr(placeholder);
    color: var(--primary-font-color);
    opacity: 0.5;
    display: block;
  }

  &:empty::before {
    display: block;
  }

  &:not(:empty)::before {
    display: none;
  }

  &:hover {
    background-color: var(--primary-background-color-accent) !important;
  }

  &:focus {
    text-overflow: unset;
    background-color: var(--primary-background-color-accent) !important;
    transition: .2s;
  }
}
</style>