<script async setup lang="ts">
import type { HLReferenceMeta } from '@Heirloom/common';
import { support } from '@app/state/common';
import { Icon } from '@iconify/vue'
import Paragraph from '@app/components/typography/Paragraph.vue'
import { Badge } from '@app/components/ui/badge'
import Rows from '@app/components/ui/flex/Rows.vue'
import Small from '@app/components/typography/Small.vue';

const props = defineProps<{
  reference: HLReferenceMeta
}>()
</script>

<template>
  <Rows class="reference-card" gap="10">
    <div class="thumb">
      <Badge class="date">
        {{ new Date(props.reference.created).toNiceDateTime() }}
      </Badge>
      <img crossorigin="anonymous" :src="reference.thumbnail ?? '../../assets/img/test.png'" height="435px" />
      <Badge class="status" variant="secondary">
        <div class="text-rose-500" @click.stop.prevent="support"
          v-if="props.reference.status != 'indexed' && new Date(props.reference.created).valueOf() < Date.now() - 1000 * 60 * 20">
          Contact Support
        </div>
        <template v-else>
          <div class="text-indigo-500" v-if="props.reference.status === 'created'">
            Uploading
          </div>
          <div class="text-indigo-500" v-if="props.reference.status === 'processing'">
            Parsing
          </div>
          <div class="text-indigo-500" v-if="props.reference.status === 'parsed'">
            Indexing
          </div>
          <div class="text-emerald-500" v-if="props.reference.status === 'indexed'">
            Ready to use
          </div>
        </template>
      </Badge>
    </div>
    <Small class="title">
      {{ props.reference.name.replace('.pdf', '') || 'Untitled' }}
    </Small>
  </Rows>
</template>

<style scoped lang="scss">
.reference-card {
  min-width: 220px;
  max-width: 310px;
  width: minmax(220px, 310px);
  cursor: pointer;
  transition: .2s;

  img {
    object-fit: cover;
    width: minmax(220px, 310px);
    aspect-ratio: 1 / 1.414;
    transition: .2s;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.thumb {
  position: relative;
  overflow: hidden;
  border-radius: calc(0.5em - 2px);
  border: 1px solid hsl(var(--border));
  background-color: #fff;

  .status {
    position: absolute;
    bottom: 3px;
    top: unset;
    right: unset;
    background-color: hsl(var(--background));
    white-space: nowrap;
    opacity: 1;
    padding: 5px 4px;
    cursor: pointer;
    left: 3px;
  }

  .date {
    position: absolute;
    top: 3px;
    white-space: nowrap;
    opacity: 1;
    z-index: 1;
    padding: 5px 4px;
    cursor: pointer;
    right: 3px;
  }
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
  z-index: 1;
}
</style>