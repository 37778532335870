<script async setup lang="ts">
import ComplianceItem from '@app/components/compliance/Item.vue';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@app/components/ui/alert"
import { compliance, loadCompliance } from '@app/state/compliance';
import { loadResponse } from '@app/state/response';
import { solicitation } from '@app/state/solicitation';
import { ref, watch, computed } from 'vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
import { Icon } from '@iconify/vue';
import { Button } from '@app/components/ui/button';
import { Card } from '@app/components/ui/card';
import Large from '@app/components/typography/Large.vue';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/components/ui/collapsible'

const isMandatoryOpen = ref(true)
const isQualificationsOpen = ref(true)
const isKnowledgeOpen = ref(true)
const isExperienceOpen = ref(true)
const isOtherOpen = ref(true)

watch(solicitation, sol => sol && loadCompliance(sol.id))
if (solicitation.value) loadCompliance(solicitation.value?.id)
watch(solicitation, sol => sol && loadResponse(sol.id))
if (solicitation.value) loadResponse(solicitation.value?.id)


const Mandatory = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'mandatory' || c.category?.toLowerCase() === 'go/no go')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Qualifications = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'qualifications')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Knowledge = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'knowledge')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Experience = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'experience')
  .sort((a, b) => a.id.localeCompare(b.id))
)
const Other = computed(() => (compliance.value ?? [])
  .filter(c => c.category?.toLowerCase() === 'other')
  .sort((a, b) => a.id.localeCompare(b.id))
)


</script>

<template>
  <Rows padding="20px" gap="20">
    <Alert variant="destructive">
      <Icon icon="lucide:triangle-alert" />
      <AlertTitle>Warning: Automated compliance checks are highly experimental.</AlertTitle>
      <AlertDescription>
        We encourage you to <button variant="link" href="mailto:nancy@govpro.ai">leave us feedback.</button>
      </AlertDescription>
    </Alert>
    <Rows flex="1" gap="20">
      <Rows v-if="Mandatory.length > 0">
        <Large>
          Mandatory Requirements (GO/NO GO)
        </Large>
        <ComplianceItem v-for="(item, i) in Mandatory" :item="item" :key="item.id" :order="i" />
      </Rows>

      <Rows v-if="Qualifications.length > 0">
        <Large>
          Qualifications
        </Large>
        <ComplianceItem v-for="(item, i) in Qualifications" :item="item" :key="item.id" :order="i" />
      </Rows>

      <Rows v-if="Knowledge.length > 0">
        <Large>
          Knowledge
        </Large>
        <ComplianceItem v-for="(item, i) in Knowledge" :item="item" :key="item.id" :order="i" />
      </Rows>

      <Rows v-if="Experience.length > 0">
        <Large>
          Experience
        </Large>
        <ComplianceItem v-for="(item, i) in Experience" :item="item" :key="item.id" :order="i" />
      </Rows>

      <Rows v-if="Other.length > 0">
        <Large>
          Other
        </Large>
        <ComplianceItem v-for="(item, i) in Other" :item="item" :key="item.id" :order="i" />
      </Rows>
    </Rows>
  </Rows>
</template>

<style lang="scss" scoped></style>