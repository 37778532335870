<script async setup lang="ts">
import { Button } from '@app/components/ui/button'
import { Icon } from '@iconify/vue'
import { TOKEN, bind } from '@app/state/auth';
import { API, slug, version } from '@app/state/common';
import { login } from '@app/lib/msal';

const auth = async () => {
    if (!slug) throw new Error('Slug is required')
    const idToken = await login()
    console.log("Microsoft token", idToken)
    const { token } = await API('/auth/microsoft', { idToken, slug, version })
    if (!token) throw new Error('Failed to authenticate with Microsoft')
    TOKEN.value = token
    return bind()
}
</script>

<template>
    <Button variant="outline" type="button" @click="auth">
        <Icon icon="tabler:brand-windows" class="brand-icon" />
        Sign in with Microsoft
    </Button>
</template>

<style lang="scss" scoped>
.brand-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}
</style>