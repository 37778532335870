<script async setup lang="ts">
import type { HLSolicitationMeta } from '@Heirloom/common';
import Button from '@app/components/ui/button/Button.vue';
import { Icon } from '@iconify/vue'
import { support } from '@app/state/common';
import { listSolicitations, solicitations } from '@app/state/solicitation';
import { App, app, tab, tabs } from '@app/state/view';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@app/components/ui/table"

listSolicitations()

const openSolicitation = (solicitation: HLSolicitationMeta) => {
  const exists = tabs.value.find(tab => tab.type === 'solicitation' && tab.id === solicitation.id)
  if (exists) tab.value = exists
  else tab.value = tabs.value[
    tabs.value.push(
      { name: solicitation.name.replace('.pdf', ''), type: 'solicitation', id: solicitation.id, solicitation }
    ) - 1
  ]
  app.value = App.TAB
}
</script>

<template>
  <Table class="solicitation-list" v-if="solicitations.length > 0">
    <TableHeader>
      <TableRow>
        <TableHead>Solicitation</TableHead>
        <TableHead>Upload Date</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Actions</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>

      <TableRow @click="openSolicitation(solicitation)" v-for="solicitation in solicitations" :key="solicitation.id"
        :solicitation="solicitation" class="solicitation-line">
        <TableCell>{{ solicitation.name.replace('.pdf', '') || 'Untitled' }}</TableCell>
        <TableCell>
          <div class="status">
            <Icon icon="lucide:clock" />
            {{ new Date(solicitation.created).toNicerDateTime() }}
          </div>
        </TableCell>
        <TableCell>
          <div class="status">
            <div class="red" @click.stop.prevent="support"
              v-if="solicitation.status === 'error' && new Date(solicitation.created).valueOf() < Date.now() - 1000 * 60 * 20">
              <Icon icon="lucide:bug" color="#c75957" />
              Contact Support
            </div>
            <template v-else>
              <div class="blue" v-if="solicitation.status === 'created'">
                <Icon icon="lucide:cloud-upload" color="#5b83ff" />
                Created
              </div>
              <div class="blue" v-if="solicitation.status === 'processing'">
                <Icon icon="lucide:loader" color="#5b83ff" />
                Processing
              </div>
              <div class="blue" v-if="solicitation.status === 'parsed'">
                <Icon icon="lucide:text-quote" color="#5b83ff" />
                Parsed
              </div>
              <div class="blue" v-if="solicitation.status === 'tagging'">
                <Icon icon="lucide:tag" color="#5b83ff" />
                Tagging
              </div>
              <div class="blue" v-if="solicitation.status === 'extraction'">
                <Icon icon="lucide:file-output" color="#5b83ff" />
                Extracting
              </div>
              <div class="blue" v-if="solicitation.status === 'solutioning'">
                <Icon icon="lucide:lightbulb" color="#5b83ff" />
                Solutioning
              </div>
              <div class="blue" v-if="solicitation.status === 'generating'">
                <Icon icon="lucide:cpu" color="#5b83ff" />
                Generating
              </div>
              <div class="blue" v-if="solicitation.status === 'outlining'">
                <Icon icon="lucide:list" color="#5b83ff" />
                Outlining
              </div>
              <div class="blue" v-if="solicitation.status === 'writing'">
                <Icon icon="lucide:pen-tool" color="#5b83ff" />
                Writing
              </div>
              <div class="blue" v-if="solicitation.status === 'checking'">
                <Icon icon="lucide:check-circle" color="#5b83ff" />
                Checking
              </div>
              <div class="blue" v-if="solicitation.status === 'compliance'">
                <Icon icon="lucide:shield-check" color="#5b83ff" />
                Compliance Check
              </div>
              <div class="green" v-if="solicitation.status === 'complete'">
                <Icon icon="lucide:circle-check-big" color="#58c693" />
                Complete
              </div>
              <div class="red" v-if="solicitation.status === 'deleted'">
                <Icon icon="lucide:trash-2" color="#c75957" />
                Deleted
              </div>
            </template>
          </div>
        </TableCell>
        <TableCell><Button variant="secondary">Action</Button></TableCell>
      </TableRow>
    </TableBody>
  </Table>
</template>

<style scoped lang="scss">
.solicitation-list {
  margin-top: 20px;
}

.solicitation-line {
  cursor: pointer;
  user-select: none;
  height: 50px;
}

.status,
.status div {
  white-space: nowrap;
  display: inline-flex;
}

svg {
  margin-right: 4px;
  margin-top: 3px;
}

.red {
  color: #c75957;
}

.blue {
  color: #5b83ff;
}

.green {
  color: #58c693;
}

thead {
  text-align: left;
}</style>