<script lang="ts" setup>
import { Icon } from '@iconify/vue';
import { support } from '@app/state/common';
import Button from '@app/components/ui/button/Button.vue';
import Large from '@app/components/typography/Large.vue';
import Small from '@app/components/typography/Small.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';
</script>

<template>
  <Columns maxWidth="500px" cente middle margin="auto">
    <Rows middle center flex="1" gap="20" class="text-center" height="100vh">
      <Icon icon="lucide:milestone" height="4em" />
      <Large>Seems like you're lost.</Large>
      <Rows margin="-10px 0 0 0">
        <Small>For help navigating GovPro's intranet, please contact your representative.</Small>
      </Rows>
      <Columns width="180px">
        <Button variant="secondary" @click="support">Contact us</Button>
      </Columns>
    </Rows>
  </Columns>
</template>

<style lang="scss" scoped></style>
