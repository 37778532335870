<script async setup lang="ts">
import AddSolicitation from '@app/components/write/AddSolicitation.vue';
import Grid from '@app/components/write/Grid.vue'
import List from '@app/components/write/List.vue'
import { Icon } from '@iconify/vue'
import { solicitations } from '@app/state/solicitation';
import Rows from '@app/components/ui/flex/Rows.vue'
import Columns from '@app/components/ui/flex/Columns.vue'
import Header from '@app/components/ui/header/Header.vue'
import Large from '@app/components/typography/Large.vue'
import Button from '@app/components/ui/button/Button.vue'

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@app/components/ui/tabs"
</script>

<template>
  <Rows style="width: 100%" height="100%">
    <Rows height="100vh">
      <Header>
        <Columns spread>
          <Columns left center gap="10">
            <Columns width="fit-content">
              <Large>
                Write Proposals
              </Large>
            </Columns>
            <Columns width="fit-content">
              <Button variant="outline" size="sm">
                <template #icon>
                  <Icon icon="lucide:shield-check" />
                </template>
                Secure
              </Button>
            </Columns>
          </Columns>
          <Columns width="220px">
            <AddSolicitation />
          </Columns>
        </Columns>
      </Header>
      <Rows v-if="solicitations.length <= 0" middle center flex="1" gap="20" class="text-center">
        <Icon icon="lucide:cloud-upload" height="4em" />
        Upload RFPs, RFQs, or RFIs by clicking "Add Opportunity" above.
      </Rows>
      <Rows v-else scroll padding="25px">
        <Rows flex="1">
          <Tabs default-value="grid">
            <TabsList>
              <TabsTrigger value="grid">
                <Icon icon="lucide:layout-grid" height="20px" />
              </TabsTrigger>
              <TabsTrigger value="list">
                <Icon icon="lucide:align-justify" height="20px" />
              </TabsTrigger>
            </TabsList>
            <TabsContent value="grid">
              <Grid />
            </TabsContent>
            <TabsContent value="list">
              <List />
            </TabsContent>
          </Tabs>
        </Rows>
      </Rows>
    </Rows>
  </Rows>
</template>

<style scoped lang="scss"></style>