import { createWebHistory, createRouter, type RouteRecordRaw } from "vue-router"
import Anchor from "@app/views/Anchor.vue"
import Tenant from "@app/views/Tenant.vue"
import Admin from "@app/views/Admin.vue"
import Bid from "@app/views/Bid.vue"
import { app, tab, tabs, App } from "@app/state/view"
import { nextTick } from "vue"

const routes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "GovPro Intranet",
		component: Anchor,
	},
	{
		path: "/tomato",
		name: "GovPro Bid | 🍅",
		component: Admin,
	},
	{
		path: "/t/:tenant",
		name: "GovPro Bid",
		component: Bid,
	},
	{
		path: '/t/:tenant/write',
		name: 'GovPro Bid | Write',
		component: Bid,
	},
	{
		path: '/t/:tenant/library',
		name: 'GovPro Bid | Library',
		component: Bid,
	},
	{
		path: '/t/:tenant/chat',
		name: 'GovPro Bid | Chat',
		component: Bid,
	},
	{
		path: '/t/:tenant/feed',
		name: 'GovPro Bid | Feed',
		component: Bid,
	},
	{
		path: '/t/:tenant/write/:id',
		name: 'GovPro Bid | Opportunity',
		component: Bid,
	},
	{
		path: '/t/:tenant/library/:id',
		name: 'GovPro Bid | Reference',
		component: Bid,
	},
	{
		path: '/t/:tenant/chat/:id',
		name: 'GovPro Bid | Conversation',
		component: Bid,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "Are you lost?",
		component: Anchor,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	}
})
export default router

router.beforeEach((to, from, next) => {
  if (to.name?.toString().startsWith('GovPro Bid')) {
    next()
    const tenant = to.params.tenant;
    const id = to.params.id;
    switch (to.name) {
      case 'GovPro Bid':
        app.value = App.NONE
				document.title = `GovPro Bid`
        break;
      case 'GovPro Bid | Write':
        app.value = App.WRITE
				document.title = `GovPro Bid | Write`
        break;
      case 'GovPro Bid | Library':
        app.value = App.LIBRARY
				document.title = `GovPro Bid | Library`
        break;
      case 'GovPro Bid | Chat':
        app.value = App.CHAT
				document.title = `GovPro Bid | Chat`
        break;
      case 'GovPro Bid | Opportunity':
      case 'GovPro Bid | Reference':
      case 'GovPro Bid | Conversation':
        const t = tabs.value.find(t => t.id === id)
        if (!t) break;
        app.value = App.TAB
        tab.value = null
        nextTick(() => {
          tab.value = t
        })
        document.title = `GovPro Bid | ${t.name}`
        break;
      default:
        app.value = App.NONE
				document.title = `GovPro Bid`
        break;
    }
  } else next()
})

